import { render, staticRenderFns } from "./Prize-Wheel.vue?vue&type=template&id=136b8ca4"
import script from "./Prize-Wheel.vue?vue&type=script&lang=js"
export * from "./Prize-Wheel.vue?vue&type=script&lang=js"
import style0 from "./Prize-Wheel.vue?vue&type=style&index=0&id=136b8ca4&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports